<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #beforecmsleftcomponents>
      <div id="chatcontainer" class="flex-1 overflow-y-auto md:px-4">
        <div v-for="(ms, d) in messages" :key="d">
          <div class="flex justify-center">
            <div class="bg-white font-bold p-1 rounded text-sm">{{formatDate(d)}}</div>
          </div>
          <message
            v-for="(m, i) in ms"
            :key="i" :message="m.Message"
            :title="m.UserObj.Name"
            :time="m.Time"
            :direction="m.User === user.Oid ? 'end' : 'start'">
          </message>
        </div>
      </div>
      <div id="inputcontainer" class="bg-white border-t border-gray-400 z-index-50 px-4 py-4" :style="inputStyles">
        <form @submit.prevent="sendMessage" class="w-full flex">
          <input ref="input" type="text" v-model="message" class="flex-1" />
          <b-button
            :disabled="!message"
            type="is-text"
            native-type="submit"
            icon-right="send" />
        </form>
      </div>
    </template>
  </page>
</template>

<script>
import moment from 'moment'
import { HEADER_HEIGHT } from '@/layouts/vaddress/Main/Header'
import CMSPage from '../base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    Message: () => import('@/components/vaddress/chat/Message')
  },
  data () {
    return {
      message: '',
      scrolling: false
    }
  },
  async mounted () {
    await this.$store.dispatch('socket/getHistory')
    this.$store.dispatch('socket/readNotification')
    this.scrollToBottom()

    document.addEventListener('scroll', this.onScroll)
  },
  methods: {
    async sendMessage () {
      const { message } = this
      if (!message) return
      this.message = ''
      await this.$store.dispatch('socket/sendMessage', message)
      this.scrollToBottom()
    },
    afterRequestCMSData () {
      const content = document.getElementById('chatcmsleft')
      if (!content) return
      const div = content.firstElementChild
      div.style.display = 'flex'
      div.style.flexDirection = 'column'
      const input = document.getElementById('inputcontainer')
      div.style.paddingBottom = `calc(${input.offsetHeight}px + 1rem)`
      if (!this.isSm) {
        div.style.height = `calc(100vh - 3rem - ${HEADER_HEIGHT})`
      }
      this.$refs.input.focus()
      this.scrollToBottom()
    },
    scrollToBottom () {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
      }, 200)
    },
    onScroll () {
      console.log('scrolling')
      this.scrolling = true
    },
    formatDate (date) {
      return moment(date).format('DD MMM')
    }
  },
  computed: {
    room () {
      return this.$store.state.socket.room
    },
    messages () {
      if (!this.room) return []
      return this.$store.state.socket.room.Details
    },
    messageLength () {
      return Object.values(this.messages).reduce((p, v) => {
        return p.concat(v)
      }, []).length
    },
    inputStyles () {
      if (this.isSm) {
        return {
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0
        }
      } else {
        return {
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0
        }
      }
    }
  },
  watch: {
    messageLength (p, v) {
      if (p !== v && !this.scrolling) {
        this.scrollToBottom()
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style>

</style>
